import React from "react";
import Bs4Rows from "./clinical/Bs4Rows";
import Pp1Rows from "./clinical/Pp1Rows";
import Ps4Rows from "./clinical/Ps4Rows";
import Ps2Rows from "./clinical/Ps2Rows";
import Pm3Rows from "./clinical/Pm3Rows";
import Bs2Rows from "./clinical/Bs2Rows";
import BpRows from "./clinical/BpRows";

const ClinicalPane = () => {
  const components = [
    { component: <Ps4Rows />, id: "ps4" },
    { component: <Pp1Rows />, id: "pp1" },
    { component: <Bs4Rows />, id: "bs4" },
    { component: <Ps2Rows />, id: "ps2" },
    { component: <Pm3Rows />, id: "pm3" },
    { component: <Bs2Rows />, id: "bs2" },
    { component: <BpRows category="bp2" fieldPostfix="same" />, id: "bp2" },
    { component: <BpRows category="bp5" fieldPostfix="diff" />, id: "bp5" },
  ];

  return (
    <>
      {components.map(({ component, id }, index) => (
        <div
          key={id}
          className={`criteria-section ${
            index == 0 ? "first-criteria-section" : ""
          }`}>
          {component}
        </div>
      ))}
    </>
  );
};

export default ClinicalPane;
