import AsyncCache from "./AsyncCache";
import { DescriptionData } from "./Common";
import {
  fetchFunctionalCriteriaPath,
  saveVariantDescriptionsPath,
} from "./Curation";
import { Domain, Gene, PartialGene } from "./Gene";

const patchParams = (body: any): RequestInit => {
  const token =
    document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content") || "";

  return {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-CSRF-Token": token,
    },
    body: JSON.stringify(body),
  };
};

export function riphubPDFUrl(pmid: string) {
    return `/articles/pmid/${pmid}/pdf`;
}

// absURL converts a path into an absolute URL, which is necessary for making
// HTTP requests from within the test suite. All requests should therefore use
// this to ensure testability.
export function absURL(path: string) {
  return new URL(path, window.location.toString());
}

// absURLStr converts a path into an absolute URL, which is necessary for making
// HTTP requests from within the test suite. All requests should therefore use
// this to ensure testability.
export function absURLStr(path: string): string {
  return absURL(path).toString();
}

export async function checkAndParse<T>(p: Promise<Response>): Promise<T> {
  const res = await p;
  if (!res.ok) {
    throw `Got HTTP ${res.status} response from ${res.url}`;
  }
  return res.json();
}

export type ArticleNoteContent = {
  note_text: string;
};

export type ArticleNoteRecord = ArticleNoteContent & {
  status: string;
  note_updated_at: string;
  note_editor: string;
};

export type ArticleNoteStatus = {
  status: string;
  error?: string;
};

export async function fetchArticleNote<T>(
  pmid: string,
  init: RequestInit | undefined = undefined
): Promise<ArticleNoteRecord> {
  return await checkAndParse<ArticleNoteRecord>(
    fetch(absURLStr(`/articles/pmid/${pmid}/note`), init)
  );
}

export async function upsertArticleNote<T>(
  pmid: string,
  record: ArticleNoteContent,
  projectID?: string
): Promise<ArticleNoteStatus> {
  return await checkAndParse<ArticleNoteStatus>(
    fetch(absURLStr(`/articles/pmid/${pmid}/note?project_id=${projectID}`), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(record),
    })
  );
}

export async function deleteArticleNote<T>(
  pmid: string,
  projectID?: string
): Promise<ArticleNoteStatus> {
  return await checkAndParse<ArticleNoteStatus>(
    fetch(absURLStr(`/articles/pmid/${pmid}/note?project_id=${projectID}`), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
  );
}

export type DisabledVariantCategoryContent = ArticleNoteContent & {
  variant_id?: string;
  category?: string;
};

export type DisabledVariantCategoryRecord = DisabledVariantCategoryContent & {
  note_editor: string;
};

export async function fetchDisabledVariantCategory<T>(
  projectID: string,
  id: string,
  init: RequestInit | undefined = undefined
): Promise<DisabledVariantCategoryRecord> {
  return await checkAndParse<DisabledVariantCategoryRecord>(
    fetch(
      absURLStr(`/projects/${projectID}/disabled_variant_categories/${id}`),
      init
    )
  );
}

export async function createDisabledVariantCategory<T>(
  projectID: string,
  record: DisabledVariantCategoryContent
): Promise<ArticleNoteStatus> {
  return await checkAndParse<ArticleNoteStatus>(
    fetch(absURLStr(`/projects/${projectID}/disabled_variant_categories`), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(record),
    })
  );
}

export async function updateDisabledVariantCategory<T>(
  projectID: string,
  id: string,
  record: DisabledVariantCategoryContent
): Promise<ArticleNoteStatus> {
  return await checkAndParse<ArticleNoteStatus>(
    fetch(
      absURLStr(`/projects/${projectID}/disabled_variant_categories/${id}`),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(record),
      }
    )
  );
}

export async function deleteDisabledVariantCategory<T>(
  projectID: string,
  id: string
): Promise<ArticleNoteStatus> {
  return await checkAndParse<ArticleNoteStatus>(
    fetch(
      absURLStr(`/projects/${projectID}/disabled_variant_categories/${id}`),
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
  );
}

export type ErrorState = {
  [index: string]: string[];
};

export type GeneUpdateStatus = {
  ok: boolean;
  errors: ErrorState | null;
  gene: Gene | null;
  features: Domain[] | null;
};

export async function updateGene<T>(
  record: PartialGene,
  domains?: Domain[]
): Promise<GeneUpdateStatus> {
  const gene = { ...record };

  if (domains) {
    gene["gene_features_attributes"] = domains;
  }

  const body = { gene, symbol: record.symbol, commit: "Update Gene" };
  const response = await fetch(
    absURLStr(`/genes/${record.symbol}`),
    patchParams(body)
  );

  return { ok: response.ok, ...(await response.json()) };
}

export const fetchFunctionalCritiera = async (symbol: string): Promise<any> => {
  return await checkAndParse<any>(
    fetch(absURLStr(fetchFunctionalCriteriaPath(symbol)))
  );
};

export const submitVariantDescriptions = async (
  projectId: string,
  variantId: string,
  pmid: string,
  req: any
): Promise<any> => {
  return await checkAndParse<any>(
    fetch(
      absURLStr(saveVariantDescriptionsPath(projectId, variantId, pmid)),
      patchParams(req)
    )
  );
};
